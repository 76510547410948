import { mutateGraphQL } from '@/data';
import { gql } from '@apollo/client';
import axios from 'axios';
import { toLower, upperFirst } from 'lodash-es';
import { GatewayCredentials } from '../../../../types/gateway';
import { PaymentMethod } from '../../../../types/quickbooks';
import type { MutationPaymentWriteArgs, PaymentValidator } from '../../../../types/schema';
import { ECOMMERCE_ENDPOINT } from '../clover';
import { postQB, queryQB } from '../quickbooks.utils';
import type PaymentProcessor from './paymentProcessor';

export const convertPayment = ( gateway, data ) => {
	if ( !data ) return null;
	return {
		type          : 'CARD' || undefined,
		status        : 'PAID',
		amount        : data.amount,
		client        : data.clientId,
		tip           : 0,
		note          : data?.note || '',
		checkNumber   : null,
		refundedAmount: 0,
		gateway       : gateway.id,
		createdAt     : data?.createdAt ? new Date( data.createdAt ) : null,
		externalValue : data.externalValue || null,
	} as PaymentValidator;
};

const ACH_NAME = 'Bank Transfer';

export const createPayment = async ( gateway: GatewayCredentials, {
	type,
	note,
	amount,
	qbOrderId,
	paymentId,
	client,
	createdAt,
	checkNumber,
	cardBrand,
} ) => {
	const methods = await queryQB( gateway, 'Select * From PaymentMethod' )
		.then( ( { data } ) => data.QueryResponse.PaymentMethod );
	const paymentType = toLower( type );
	const cardType = toLower( cardBrand ) || 'visa';
	
	console.debug( { methods } );
	
	const getPaymentMethod = async ( type: 'CREDIT_CARD' | 'NON_CREDIT_CARD',
		methods: PaymentMethod[],
		methodName: string,
		findFunc?: ( pm: PaymentMethod ) => boolean ) => {
		const defaultFindFunc = ( method: PaymentMethod ) => {
			const payMethodName = toLower( method.Name );
			return method.Type === type && ( payMethodName === methodName || payMethodName.slice( 0, 3 ) === methodName?.slice( 0, 3 ) );
		};
		const paymentMethod = methods?.find( findFunc || defaultFindFunc );
		if ( paymentMethod ) return paymentMethod;
		const { data } = await postQB( gateway, '/paymentmethod', { Name: upperFirst( methodName ), Type: type } );
		return data;
	};
	
	const paymentMethod: PaymentMethod = paymentType === 'ach'
		? await getPaymentMethod(
			'NON_CREDIT_CARD',
			methods,
			ACH_NAME,
			( pm ) => pm.Type === 'NON_CREDIT_CARD' && [ 'ach', toLower( ACH_NAME ) ].includes( toLower( pm.Name ) ) )
		: !cardBrand && paymentType !== 'card' || paymentType.includes( 'saved' )
			? await getPaymentMethod( 'NON_CREDIT_CARD', methods, paymentType )
			: await getPaymentMethod( 'CREDIT_CARD', methods, cardType );
	
	console.debug( { paymentMethod } );
	
	const payload = {
		CustomerRef     : { value: client?.externalValue?.split( '-' )?.[ 1 ] },
		TxnDate         : createdAt,
		PaymentMethodRef: paymentMethod ? { value: paymentMethod.Id } : null,
		PaymentRefNum   : toLower( type ) === 'check' ? checkNumber : null,
		Line            : [ {
			Amount   : amount,
			LinkedTxn: [ { TxnId: qbOrderId, TxnType: 'Invoice' } ],
		} ],
		TotalAmt        : amount,
		PrivateNote     : note || '',
	};
	const { data } = await postQB( gateway, '/payment', payload );
	
	await mutateGraphQL<MutationPaymentWriteArgs>( {
		mutation : gql`
			mutation paymentWrite_2d06($id: String!, $input: PaymentValidator!) {
				paymentWrite(id: $id, input: $input) {
					id
				}
			}
		`,
		variables: {
			id   : paymentId,
			input: {
				externalValue: `${gateway.externalId}-${qbOrderId}-${data.Payment.Id}-quickbooks`,
			},
		},
	} );
	
	return data;
};

const quickbooksPaymentProcessor: PaymentProcessor = {
	type: 'QUICKBOOKS',
	
	createPayment,
	
	async convertPayment( gateway, location, data ) {
		return convertPayment( gateway, data ) as PaymentValidator;
	},
	
	async refundPayment( gateway, { paymentId, amount } ) {
		const { data } = await axios.post( `${ECOMMERCE_ENDPOINT}/refunds`, {
			charge: paymentId,
			amount: Math.round( amount * 100 ),
		}, { headers: { Authorization: `Bearer ${gateway.externalKey}` } } );
		return data;
	},
	
};
export default quickbooksPaymentProcessor;
