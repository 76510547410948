import axios from 'axios';
import { GatewayCredentials } from '@/types/gateway';
import { QUICKBOOKS_ENDPOINT } from '../quickbooks/quickbooks';

export const removeSpecialAlphabets = ( str: string ) => {
	if ( !str ) return '';
	return str.normalize( 'NFD' )
		.replace( /[\u0300-\u036f]/g, '' )
		.trim();
};

export const normalizeName = ( name: string ) => removeSpecialAlphabets( name ).slice( 0, 99 );

export const getQB = (
	gateway: GatewayCredentials,
	urlComponent: string,
	params: object = {},
) => axios.get( `${QUICKBOOKS_ENDPOINT}/${gateway.externalId}${urlComponent}`, {
	headers: { Authorization: `Bearer ${gateway.externalKey}` },
	params : { ...params, minorversion: 65 },
} );

export const postQB = (
	gateway: GatewayCredentials,
	urlComponent: string,
	payload: object = {},
	params: object = {},
) => axios.post( `${QUICKBOOKS_ENDPOINT}/${gateway.externalId}${urlComponent}`, payload, {
	headers: { Authorization: `Bearer ${gateway.externalKey}` },
	params : { ...params, minorversion: 65 },
} );

export const queryQB = ( gateway: GatewayCredentials, query: string ) => getQB( gateway, '/query', { query } );

